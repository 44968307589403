<script lang="ts" setup>
import { SEARCH_RESULT_TYPES } from '~/constants/searchResultTypes';
import type { OfferImage } from '~/types/NewTypes/CatalogNew';
import type { SearchByQueryResponse } from '~/types/NewTypes/Search';

defineEmits(['close-search', 'go-to-search-page']);
const props = defineProps<{ results: SearchByQueryResponse }>();

const searchStore = useSearchStore();

onMounted(() => {
  searchStore.getSearchHistory();
});

const hasSearchResults = computed(
  () =>
    !!props.results.brands.length ||
    !!props.results.categories.length ||
    !!props.results.seoCatalogPages?.length ||
    !!props.results.offers.length,
);

const filteredSearchHistory = computed(() =>
  searchStore.searchHistory.filter((item) => {
    if (!item.name) return false;

    return item.name.toLowerCase().includes(searchStore.searchQuery?.toLowerCase());
  }),
);

function getMainImage(images: OfferImage[]) {
  if (!images.length) return undefined;

  return images.find((image) => image.is_main) || images[0];
}
</script>

<template>
  <section class="search-results">
    <template v-if="filteredSearchHistory.length">
      <div
        v-for="item in filteredSearchHistory"
        :key="item.url"
        class="search-results__history-item"
      >
        <AppLink
          :to="makeUrlRelative(item.url)"
          class="flex items-center gap-1.5"
          @click="$emit('close-search')"
        >
          <SvgoHistory class="text-xl text-hell-black-60" />
          <div v-html="highlightTextOccurrence(item.name, searchStore.searchQuery)" />
        </AppLink>

        <SvgoCross
          class="text-xl text-hell-black-60 hover:text-hell-black cursor-pointer"
          @click="searchStore.removeFromSearchHistory(item)"
        />
      </div>
    </template>

    <section
      v-if="hasSearchResults"
      @click="$emit('close-search')"
    >
      <SearchResultsListItem
        v-for="brand in results.brands"
        :key="brand.slug"
        :image="brand.logo"
        :name="brand.name"
        :type="SEARCH_RESULT_TYPES.brand"
        :url="brand.uri"
      />

      <SearchResultsListItem
        v-for="category in results.categories"
        :key="category.slug"
        :image="{ url: category.svg_url } as OfferImage"
        :name="category.name"
        :type="SEARCH_RESULT_TYPES.category"
        :url="category.uri"
      />

      <SearchResultsListItem
        v-for="seoCategory in results.seoCatalogPages"
        :key="seoCategory.seo_catalog_uri"
        :image="{ url: seoCategory.svg_url } as OfferImage"
        :name="seoCategory.title"
        :type="SEARCH_RESULT_TYPES.category"
        :url="seoCategory.seo_catalog_uri"
      />

      <SearchResultsListItem
        v-for="offer in results.offers.slice(0, 6)"
        :key="offer.id"
        :image="getMainImage(offer.offer_images)"
        :name="offer.product_name"
        :price="offer.price"
        :type="SEARCH_RESULT_TYPES.offer"
        :url="offer.uri"
      />

      <a
        class="search-results__bottom-text cursor-pointer"
        @click="$emit('go-to-search-page')"
      >
        Все результаты
      </a>
    </section>

    <AppLink
      v-else-if="searchStore.searchQuery.length"
      class="search-results__bottom-text"
      to="/catalog"
      @click="$emit('close-search')"
    >
      Посмотреть каталог
    </AppLink>

    <section
      v-else
      class="search-results__bottom-text"
    >
      Введите поисковый запрос
    </section>
  </section>
</template>

<style lang="scss" scoped>
.search-results {
  overflow-y: auto;
  width: 100%;
  height: fit-content;
  max-height: 65vh;
  font-size: $text-xs;

  @include screen-sm {
    max-height: 60vh;
    font-size: $text-sm;
  }

  &__history-item {
    display: grid;
    grid-template-columns: 1fr 24px;
    gap: 16px;
    align-items: center;
    height: 40px;
    border-bottom: 1px solid $hell-black-20;
    font-size: 14px;
  }

  &__bottom-text {
    display: block;
    width: 100%;
    padding: 16px 8px 8px;
    font-weight: $bold;
    font-size: $text-xs;
    text-align: center;
  }
}
</style>

<script lang="ts" setup>
const authStore = useAuthStore();
const cartStore = useCartStore();
const route = useRoute();

const cartBadge = computed(() => {
  let itemsInCart = 0;

  cartStore.mappedCartItems.forEach((item) => {
    if (item?.count) itemsInCart += item.count;
  });

  return itemsInCart;
});
</script>

<template>
  <nav class="mobile-nav">
    <MenuActionBtn
      :is-active="route.path === '/'"
      label="Главная"
      top-icon="Home"
      url="/"
    />

    <MenuActionBtn
      :is-active="route.path.includes('/catalog')"
      label="Каталог"
      top-icon="Catalog"
      url="/catalog"
    />

    <MenuActionBtn
      :counter-badge="cartBadge"
      :is-active="route.path === '/cart'"
      label="Корзина"
      top-icon="Cart"
      url="/cart"
    />

    <MenuActionBtn
      :is-active="route.path === '/favorites'"
      label="Избранное"
      top-icon="Heart"
      url="/favorites"
    />

    <MenuActionBtn
      :is-active="authStore.isLogged ? String(route.path).includes('/profile') : route.path === '/login'"
      :label="authStore.isLogged ? 'Профиль' : 'Войти'"
      :top-icon="authStore.isLogged ? 'Profile' : 'Login'"
      :url="authStore.isLogged ? '/profile' : '/login'"
    />
  </nav>
</template>

<style lang="scss" scoped>
.mobile-nav {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 105;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - var(--scrollbar-width));
  padding: 5px 5px 20px;
  border-top: 1px solid $hell-black-10;
  background-color: $white;

  @include screen-md {
    display: none;
  }
}
</style>

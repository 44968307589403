<script lang="ts" setup>
const siteStore = useSiteStore();
</script>

<template>
  <div class="above-header-block">
    <div />

    <AppLink :to="siteStore.aboveHeaderBlock.url">
      {{ siteStore.aboveHeaderBlock.text }}
    </AppLink>

    <button
      type="button"
      @click="siteStore.hideAboveHeaderBlock"
    >
      <SvgoCross
        :font-controlled="false"
        class="size-6 mr-3"
        filled
      />
    </button>
  </div>
</template>

<style lang="scss" scoped>
.above-header-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  padding: 4px;
  background-color: $hell-black-80;
  color: $white;
  font-weight: $bold;
  font-size: $text-sm;

  @include screen-md {
    height: 48px;
    font-size: $text-base;
  }
}
</style>
